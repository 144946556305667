<template>
  <h1>Contact</h1>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped></style>
